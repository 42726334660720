'use client';

import { useRouter } from 'app/providers/i18n/i18n.config';
import { useTranslations } from 'next-intl';

import { Button } from 'ui/button/button';
import Logo from 'ui/logo/logo';

import styles from './not-found-component.module.scss';

export const NotFoundComponent = () => {
  const t = useTranslations('notfound');
  const router = useRouter();

  return (
    <div className={styles.error_404}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>404</h1>
          <span className={styles.description}>{t('sorry')}</span>
          <div className={styles.btn}>
            <Button fullWidth size='l' onClick={() => router.push('/')}>
              {t('Go to Homepage')}
            </Button>
          </div>
        </div>
        <div className={styles.footer}>
          <Logo />
        </div>
      </div>
    </div>
  );
};
